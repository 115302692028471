import * as Sentry from "@sentry/nextjs";
import memoizee from "memoizee";

import {api} from "../../_services/api";
import {MsMap} from "../../constants/MsMap";
import {
  Care,
  CareEntrySearchResult,
  CareTopic,
  CareTopicRaw,
  Hit,
  RawCareEntrySearchResult,
  RelatedCare,
  RelatedCareOverrideWrapper,
  RelatedTopic,
  RelatedTopicOverrideWrapper,
  SearchResponse,
} from "./types";

const fetchFields: (keyof RawCareEntrySearchResult)[] = [
  "name",
  "appointment_reason",
  "description",
  "brief_description",
  "slug",
  "cares",
  "related_topics",
];

export const fetchCareEntries = (
  query: string,
  options?: {limit?: number; offset?: number},
): Promise<CareEntrySearchResult[]> =>
  api
    .post("/hib/care/search", {
      must: [
        {
          text: query,
          type: "TYPE_CUMULATIVE",
          fuzzy: true,
        },
      ],
      should: [
        {
          text: query,
          type: "TYPE_STANDARD",
          fuzzy: false,
        },
      ],
      limit: options?.limit || 40,
      offset: options?.offset ?? 0,
      fetch_fields: fetchFields,
    })
    .then(res => res.json())
    .then((json: SearchResponse<Hit<RawCareEntrySearchResult>>) =>
      json.hits.map(result => result.document),
    )
    .then(results => results.map(applyOverrides));

export const applyCareOverride = ({
  care = {} as RelatedCare,
  ...overrides
}: RelatedCareOverrideWrapper): RelatedCare => ({...care, ...overrides.pickBy()});

export const applyRelatedTopicOverrides = ({
  topic = {} as RelatedTopic,
  ...overrides
}: // @ts-expect-error TS2322: Type '{ brief_description: string | null; name: string | null; description: string | null; insurance_type: CareInsuranceType; locale: string; rank_boost: number; slug: string; }' is not assignable to type 'RelatedTopic'.
RelatedTopicOverrideWrapper): RelatedTopic => ({...topic, ...overrides.pickBy()});

/**
 * If an entry has a related care that has been unpublished, it will not have a slug.
 * This filters out such entries.
 */
const isValidCare = (care: RelatedCare) => Boolean(care.slug);

const applyOverrides = (result: RawCareEntrySearchResult) => ({
  ...result,
  cares: result.cares?.map(applyCareOverride).filter(isValidCare) || [],
  related_topics: result.related_topics?.map(applyRelatedTopicOverrides) || [],
});

type PossibleCareResponse = CareTopicRaw | Care | CareEntrySearchResult | null;

export const isCareReason = (content: PossibleCareResponse): content is Care =>
  // @ts-expect-error TS2322: Type 'boolean | null' is not assignable to type 'boolean'.
  content && "appointment_reason" in content;

export const isCareTopic = (content: PossibleCareResponse): content is CareTopicRaw =>
  // @ts-expect-error TS2322: Type 'boolean | null' is not assignable to type 'boolean'.
  content && "related_topics" in content;

export enum CareType {
  TOPIC = "TOPIC",
  REASON = "REASON",
}

type FormattedCareEntryResponse =
  | {type: CareType.TOPIC; content: CareTopic}
  | {type: CareType.REASON; content: Care}
  | null;

/**
 * This API returns both appt reason content and topic content.
 * This utility determines the type of the response
 * and applies override values for related records.
 * @param entry API response for '/hib/care/[slug]
 */
export const formatCareEntryResponse = (
  entry: PossibleCareResponse,
): FormattedCareEntryResponse => {
  if (isCareReason(entry))
    return {
      type: CareType.REASON,
      content: entry as Care,
    };

  if (isCareTopic(entry))
    return {
      type: CareType.TOPIC,
      content: applyOverrides(entry) as CareTopic,
    };

  return null;
};

export const fetchCareEntry = (slug: string): Promise<FormattedCareEntryResponse | null> =>
  api
    .get(`/hib/care/${slug}/`)
    .then(formatCareEntryResponse)
    .catch(err => {
      Sentry.captureException(err, scope => {
        scope.setContext("Context", {
          slug,
        });
        return scope;
      });
      return null;
    });

export const memoizedFetchCareEntry = memoizee(fetchCareEntry, {
  promise: true,
  maxAge: MsMap.ONE_MINUTE,
});
